<template>
  <v-sheet
    class="overflow-hidden my-2 elevation-5"
    rounded="xl"
    max-height="200"
  >
    <v-img
      :src="(image != null)?$store.state.imagePath + image.image_path:img"
      cover
      class="text-right"
    >
      <div class="pa-4">
        <v-btn icon class="primary mr-3" @click="uploadFile">
          <v-icon small color="white">fas fa-edit</v-icon>
        </v-btn>
        <v-btn v-if="image!=null" icon class="error" @click="deleteImage">
          <v-icon small color="white">fas fa-trash</v-icon>
        </v-btn>
      </div>

      <input
        type="file"
        accept="image/*"
        class="d-none"
        ref="uploaderBanner"
        @change="onFileChange"
      />
    </v-img>

   
  </v-sheet>
</template>

<script>
export default {
  props: {
    img: String,
    type: Object,
    top_banner: Object,
    client_image: Object
  },
  data() {
    return {
      site: {id:null},
      image: null,
    };
  },
  methods: {
    postImage() {
      //Verificação do id da imagem, para o Update ou Create
      this.putIdImage()

      const formData = new FormData();
      const siteKeys = Object.keys(this.site);
      const siteValues = Object.values(this.site);
      for (let i = 0; i < siteKeys.length; i++) {
        if (siteValues[i] !== null) {
          formData.append(siteKeys[i], siteValues[i]);
        }
      }
      console.log(formData)
      this.$api
        .post(this.getRoute(), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.getImage()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRoute() {
      let url
      switch (this.type.name) {
        case "card":
          url = (this.image != null)?"site/homes/update":"site/homes"
          return url;
        case "client_banner":
          url = (this.image != null)?"site/homes/update":"site/homes"
          return url;
        case "product":
          url = (this.image != null)?"site/products/update":"site/products"
          return url;
        case "top_banner":
          return "site/homes/update";
        case "clients":
          return "site/homes/update";
      }
    },
    getImage() {
      switch (this.type.name) {
        case "card":
          return this.getCardBanner();
        case "client_banner":
          return this.getClientBanner();
        case "product":
          return this.getProductBanner();
        case "top_banner":
          return this.getHomeId(this.top_banner);
        case "clients":
          return this.getHomeId(this.client_image);
      }
    },
    deleteImage() {
      switch (this.type.name) {
        case "card":
          return this.deleteHomeId(this.image.id);
        case "client_banner":
          return this.deleteHomeId(this.image.id);
        case "product":
          return this.deleteProductBanner(this.image.id);
        case "top_banner":
          return this.deleteHomeId(this.top_banner.id);
        case "clients":
          return this.deleteHomeId(this.client_image.id);
      }
    },
    putIdImage(){
      if(this.type.name== "top_banner"){
        if(this.image!=null)
          this.site.id = this.top_banner.id
      }
      if(this.type.name== "clients"){
        if(this.image!=null)
          this.site.id = this.client_image.id
      }
      if((this.type.name == "card")||(this.type.name == "client_banner")||(this.type.name == "product")){
        if(this.image!=null)
            this.site.id = this.image.id
      }
    },
    uploadFile() {
      this.$refs.uploaderBanner.click();
    },
    onFileChange(e) {
      this.imagePreview = URL.createObjectURL(e.target.files[0]);
      this.site.image_path = e.target.files[0];
      this.site.type = this.type.name;
      this.postImage();
    },
    getCardBanner() {
      this.$api
        .get("site/homes/card")
        .then((res) => {
          this.image = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getClientBanner() {
      this.$api
        .get("site/homes/client_banner")
        .then((res) => {
          this.image = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProductBanner() {
      this.$api
        .get("site/products/banner")
        .then((res) => {
          this.image = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteProductBanner() {
      this.$api
        .get('site/products/delete',{id:this.image.id})
        .then((res) => {
          this.image = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getHomeId(obj) {
      this.$api
        .get(`site/homes/${obj.id}`)
        .then((res) => {
          this.image = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteHomeId(id) {
      this.$api
        .post(`site/homes/delete`,{id:id})
        .then(() => {
          this.image = null
          this.getImage()
          // location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getImage();
  },
};
</script>
